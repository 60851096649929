[data-v-29a0118d] .avue-crud .el-table {
  height: calc(100vh - 423px) !important;
  max-height: calc(100vh - 423px) !important;
}
.knowledgeTree .common_tree_handle .el-tree[data-v-29a0118d] {
  height: calc(100vh - 500px) !important;
}
[data-v-29a0118d] .avue-tree {
  height: calc(100vh - 417px);
}
[data-v-29a0118d] .serviceType .avue-crud .el-table {
  height: calc(100vh - 600px) !important;
  max-height: calc(100vh - 600px) !important;
}
[data-v-29a0118d] .common_tree_handle .el-tree {
  /* height: 100% !important; */
  height: calc(100vh - 459px) !important;
  overflow-y: scroll;
}